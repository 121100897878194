import { defineStore } from 'pinia';

import {
	setCookie,
	getCookie,
	COOKIE_FINGERPRINT,
	COOKIE_X_CLIENT_SESSION_DEVICE,
	COOKIE_CLIENT_SESSION_KEY,
} from '@petlove/lion/libs/cookies';
import {
	mParticleLogout,
	mParticleLogin,
} from '@petlove/lion/libs/mparticle';
import { v4 as uuidv4 } from 'uuid';

export const useAuthStore = defineStore('auth', {
	state: () => ({
		loading: false,
		logged: false,
		new_client: true,
		user: {
			email: null,
			name: null,
			firstname: null,
			lastname: null,
			userToken: null,
			koalaToken: null,
			source: null,
			public_id: null,
			googleToken: null,
		},
    sso: {
      reference_id: null,
      public_id: null
    },
		onetap: { loading: false },
		googleUser: {},
	}),
	getters: {
		isLogged() {
			return this.logged;
		},
		getSession() {
			return this.user || {};
		},
		isNewClient() {
			return this.new_client;
		},
		getUserToken() {
			return this.user?.userToken || null;
		},
		subscriber() {
			return !!this.user?.subscriber;
		},
		getGoogleUserToken() {
			return this.user.googleToken;
		},
		isLoadingOneTapLogin() {
			return this.onetap.loading;
		},
		isClubMember() {
			return this.user?.discount_club_member ?? false;
		},
	},
	actions: {
		setSession({ logged, new_client, ...user }) {
			this.logged = logged;
			this.new_client = new_client;
			this.user = user || {};
		},
		setGoogleToken(googleToken) {
			this.user.googleToken = googleToken;
		},
		setLoadingOneTap(loading) {
			this.onetap.loading = loading;
		},
		async setGoogleTokenAction(googleToken) {
			googleToken && this.setGoogleToken(googleToken);
		},
		async loginSocial(user) {
			const postData = {
				email: user.email,
				source: 'google_api',
				token: user.credential,
				name: `${user.given_name} ${user.family_name}`,
				fingerprint: getCookie(COOKIE_FINGERPRINT) || '',
			};
			const headers = {
				'X-Client-Session': getClientSessionValue(),
				'X-Client-Session-Device': getClientSessionDeviceValue(),
			};

			try {
				const { data } = await $fetch('/session/social', {
					headers,
					method: 'POST',
					body: postData,
				});
				mParticleLogin(data);
				saveCredentialAtSmartLock();
				saveCredentialAtLocalStorage(user);
				window.location.reload();
			}
			catch (error) {
				console.log({ error });
			}
		},
		async fetchGoogleUserInfo() {
			const googleToken = this.getGoogleUserToken();

			try {
				const url = `https://oauth2.googleapis.com/tokeninfo?id_token=${googleToken}`;
				const { data } = await $fetch(url);

				this.loginSocial({ credential: googleToken, ...data });
			}
			catch (error) {
				console.log({ error });
			}
		},
		async fetchSession() {
			try {
				this.loading = true;
				const user = await $fetch('/service/user/current');
				this.setSession(user || {});
			}
			catch (error) {
				console.error({ error });
			}
			finally {
				this.loading = false;
			}
		},
		logout() {
			setCookie('PL_sid', '');
			mParticleLogout();
			window.sessionStorage['discountclub-vuex-persist'] = '';
			window.location.assign('/session/logout');
		},
		redirectToLogin(_state, path) {
			const _encode = (value) => {
				return Buffer.from(value).toString('hex');
			};
			const modifiedPath = path ? `/entrar/${_encode(path)}` : '/entrar';
			return window?.location?.assign(modifiedPath);
		},
	},
});

const saveCredentialAtLocalStorage = (data) => {
	if (navigator?.credentials) {
		localStorage.setItem('credential', JSON.stringify(data));
	}
};

const getClientSessionValue = () => {
	let sessionCookie = getCookie(COOKIE_CLIENT_SESSION_KEY);
	if (!sessionCookie) {
		sessionCookie = uuidv4();
	}
	// Renew Cookie for 30 minutes
	setCookie(COOKIE_CLIENT_SESSION_KEY, sessionCookie, 0, 30);

	return sessionCookie;
};

const getClientSessionDeviceValue = () => {
	let sessionCookie = getCookie(COOKIE_X_CLIENT_SESSION_DEVICE);
	if (!sessionCookie) {
		sessionCookie = uuidv4();
	}
	setCookie(COOKIE_X_CLIENT_SESSION_DEVICE, sessionCookie);

	return sessionCookie;
};

const saveCredentialAtSmartLock = () => {
	const removeLocalCredential = () => {
		localStorage.removeItem('credential');
	};
	if (navigator.credentials) {
		const data = localStorage.getItem('credential');
		if (data) {
			const credential = JSON.parse(data);
			if (
				credential.password
				&& window.PasswordCredential
				&& window.PublicKeyCredential
			) {
				let cred = new PasswordCredential({
					id: credential.id,
					password: credential.password,
					name: credential.name,
				});
				navigator.credentials.store(cred);
				removeLocalCredential();
			}
			else if (
				credential.id
				&& credential.name
				&& credential.iconURL
				&& credential.provider
				&& window.FederatedCredential
			) {
				let cred = new window.FederatedCredential({
					id: credential.id,
					name: credential.name,
					iconURL: credential.iconURL,
					provider: credential.provider,
				});
				navigator.credentials.store(cred);
				removeLocalCredential();
			}
		}
	}
};
