import { useAuthStore } from '@petlove/lion/store/auth';

export const useLabradorFetch = (request, opts) => {
  const config = useRuntimeConfig();
  const { getUserToken } = useAuthStore();
  const baseURL = import.meta.server ? config.internalBaseURL : config.public.baseURL;

  if (!baseURL) throw createError('Base URL not found');

  const options = {
    baseURL,
    headers: {},
    timeout: 3000,
    onRequest({ options }) {
      if (getUserToken) {
        options.headers.set('x-user-token', getUserToken);
      }
    },
    ...opts,
  };

  return useFetch(request, options, { deep: true });
};
