import {
	getCookie,
	COOKIE_ZIPCODE
} from '@petlove/lion/libs/cookies/index';
import { useAuthStore } from '@petlove/lion/store/auth';
import { useShipmentStore } from '@petlove/lion/store/shipment';	
import { controlPluginExecution } from '@petlove/lion/utils/controlPluginExecution';

export default defineNuxtPlugin({
	name: 'set-zipcode',
	parallel: true,
	dependsOn: ['user-session'],
	setup() {
		const configs = useRuntimeConfig();
		
		const shouldRunPlugin = controlPluginExecution(configs, 'setZipcode');
		if (!shouldRunPlugin) return;

		setLastAddressZipcode();
	},
});

function setLastAddressZipcode() {
		const { isLogged, getSession } = useAuthStore();
		const { setSelectedZipcode } = useShipmentStore();
		const selectedZipcode = getCookie(COOKIE_ZIPCODE);

		if (selectedZipcode) return setSelectedZipcode(selectedZipcode);

    	if (!isLogged || (Boolean(selectedZipcode))) return;

		const { last_address } = getSession || {};

		if (last_address?.zipcode) {
			const zipcode = last_address.zipcode;
			setSelectedZipcode(`${zipcode.slice(0, 5)}-${zipcode.slice(-3)}`);
		}
	
}