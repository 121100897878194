import { load } from '@fingerprintjs/fingerprintjs';
import { getCookie, setCookie, COOKIE_FINGERPRINT } from '@petlove/lion/libs/cookies';
import { controlPluginExecution } from '@petlove/lion/utils/controlPluginExecution';

export default defineNuxtPlugin({
	name: 'fingerprint',
	parallel: true,
	setup() {
		const configs = useRuntimeConfig();

		const shouldRunPlugin = controlPluginExecution(configs, 'fingerprint');
		if (!shouldRunPlugin) return;

		generateFingerprint();
	},
});

async function generateFingerprint(fromCookie = true) {
	if (!fromCookie) return await loadFingerprint();

	if (!getCookie(COOKIE_FINGERPRINT)) {
		const fingerprint = await loadFingerprint();
		setCookie(COOKIE_FINGERPRINT, fingerprint);
	}
}

async function loadFingerprint() {
	try {
		const fp = await load();
		const { visitorId } = await fp.get();
		return visitorId;
	}
	catch (e) {
		console.error(e);
		return '';
	}
}
