import { defineStore } from 'pinia';

import { useAuthStore } from '@petlove/lion/store/auth'; // Assuming you have refactored authStore to Pinia

export const useNotificationsStore = defineStore('notifications', {
	state: () => ({
		feed: [],
		isLoading: false,
		totalPages: 0,
		snackbar: {
			show: false,
			type: 'success',
			message: '',
		},
	}),
	actions: {
		async fetchFeed({ pageSize = 10, page = 1 }) {
			const authStore = useAuthStore();
			if (!authStore.isLogged) {
				return;
			}
			try {
				this.isLoading = true;

				const { data } = await useLabradorFetch(
					`/v1/notifications?page_size=${pageSize}&page=${page}`,
				);

				this.feed = data.feed;
				this.totalPages = data.total_pages;
			}
			catch (error) {
				console.info('No notifications', error);
			}
			finally {
				this.isLoading = false;
			}
		},
		setLoading(isLoading) {
			this.isLoading = isLoading;
		},
		setTotalPages(totalPages) {
			this.totalPages = totalPages;
		},
		setSnackbar(snackbar) {
			this.snackbar = { ...this.snackbar, ...snackbar };
		},
	},
});
