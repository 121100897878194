import { isBot } from '@petlove/lion/libs/isBot';
import { useAuthStore } from '@petlove/lion/store/auth';
import { controlPluginExecution } from '@petlove/lion/utils/controlPluginExecution';

export default defineNuxtPlugin({
	name: 'user-session',
	async setup() {
		const shouldRunPlugin = controlPluginExecution(useRuntimeConfig(), 'userSession')
		if (!shouldRunPlugin) return;

		const userAgent = window?.navigator?.userAgent || '';
		if (isBot(userAgent)) return;

		const authStore = useAuthStore();
		await authStore.fetchSession();
	},
});
