import { encode } from '@petlove/frontend-utilities-utils';
import { getCookie } from '@petlove/lion/libs/cookies';

import { useAuthStore } from '@petlove/lion/store/auth';
import { useUiStore } from '@petlove/lion/store/ui';
import { controlPluginExecution } from '@petlove/lion/utils/controlPluginExecution';

const COOKIE_SSO_LOGGED = 'PL_sso_logged';
const SSO_LOGIN_RETRIES = 'PL_sso_login_count';

export default defineNuxtPlugin({
	name: 'sso',
	parallel: true,
	dependsOn: ['user-session'],
	setup() {
		const configs = useRuntimeConfig();
		
		const shouldRunPlugin = controlPluginExecution(configs, 'sso');
		if (!shouldRunPlugin) return;

		loginUserSingleSignOn();
	},
});

async function loginUserSingleSignOn() {
	const nuxtApp = useNuxtApp();
	const { isLogged } = useAuthStore();
	const { setLoadingSingleSignOn } = useUiStore();
	const isLoggedSSO = getCookie(COOKIE_SSO_LOGGED) ?? false;
	const ssoLoginRetries = Number(localStorage.getItem(SSO_LOGIN_RETRIES)) ?? 0;
	if (!isLogged && isLoggedSSO && ssoLoginRetries === 0) {
	  try {
		const config = useRuntimeConfig();
		let baseURL = config.public.greyhoundURL;
		setLoadingSingleSignOn(true);
		if (!import.meta.dev) {
		  baseURL = window.location.origin;
		}
		const url = new URL(`${baseURL}/session/auth/sso/redirect_url`);
		if (import.meta.dev) {
		  url.searchParams.append('customCallback', encode(window.location.href));
		}
		const response = await $fetch(url.toString());
		localStorage.setItem(SSO_LOGIN_RETRIES, '1');
		if (response?.auth?.url) {
		  return (window.location.href = response.auth.url);
		}
	  } catch (error) {
		console.error(error);
		nuxtApp.$honeybadger?.notify(error, 'Error on automatic login SSO');
		return;
	  } finally {
		setLoadingSingleSignOn(false);
	  }
	} else if (isLogged) {
	  localStorage.removeItem(SSO_LOGIN_RETRIES);
	}
  }
