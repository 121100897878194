import { useUsersStore } from '@petlove/lion/store/users';
import { controlPluginExecution } from '@petlove/lion/utils/controlPluginExecution';

export default defineNuxtPlugin({
	name: 'user-public',
	parallel: true,
	dependsOn: ['user-session'],
	async setup() {
		const configs = useRuntimeConfig();

		const shouldRunPlugin = controlPluginExecution(configs, 'userPublic');
		if (!shouldRunPlugin) return;

		const userStore = useUsersStore();
		const { public: { baseURL } } = configs;
		const promises = [
			userStore.fetchUserPublic(baseURL),
			userStore.fetchUserPets(baseURL),
		];

		Promise.all(promises);
	},
});
