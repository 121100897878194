<template>
  <template v-if="status === 'error'">
    <CaEmptyState
      title="Ops, blog indisponível"
      variant="horizontal"
    />
  </template>
  <div
    v-else
    :id="id"
  >
    <div class="bg-neutral-fluid">
      <div
        class="blog"
        :class="`blog-${$device.isDesktop ? 'desktop' : 'mobile'}`"
      >
        <CaTitle
          :size="$device.isDesktop ? 'm' : 's'"
          tag="h2"
          class="font-black text-center font-title-s pt-4 pb-6"
          weight="black"
          color="primary"
        >
          {{ blog?.title }}
        </CaTitle>
        <BlogFilter
          v-model="selectedCategory"
          :categories="categories"
        />
        <HighCarousel
          v-if="$device.isMobile"
          v-model="currentIndex"
          item-class="blog-card"
        >
          <div class="blog__cards relative flex">
            <BlogCard
              v-for="(post, index) in blog?.posts"
              :key="post.id"
              :image="post.image"
              :title="post.title"
              :disabled="currentIndex !== index"
              :link="post.link"
              @click="handleClick(index)"
              @click-link="handleClickLink"
            >
              {{ post.title }}
            </BlogCard>
          </div>
        </HighCarousel>

        <CaLoading v-else-if="status === 'pending'" />

        <div
          v-else
          class="blog__cards relative flex mt-6"
        >
          <BlogCard
            v-for="(post, index) in blog?.posts"
            :key="post.id"
            class="blog__card"
            :class="currentIndex === index && 'active'"
            :image="post.image"
            :title="post.title"
            :disabled="currentIndex !== index"
            :link="post.link"
            @click="handleClick(index)"
          >
            {{ post.title }}
          </BlogCard>
        </div>
      </div>
    </div>
    <div class="curved" />
  </div>
</template>

<script setup>
import { slugify } from '@petlove/frontend-utilities-utils';

const { $gtm } = useNuxtApp();

const selectedCategory = ref('');
const currentIndex = ref(1);

const id = '7cd86e30-9bcd-46ff-a68d-3a94cc5e77da';
const {
  data: blog,
  status,
  error,
} = await useLabradorFetch(`/v2/new-home/component/${id}`, {
  params: {
    category: selectedCategory,
  },
  transform: blog => blog.data,
  key: 'blog',
  lazy: true,
});

const { categories, current_category } = blog?.value || {};

if (current_category) {
  selectedCategory.value = current_category;
}

const handleClick = (index) => {
  if (currentIndex.value !== index) {
    currentIndex.value = index;
  }
};

const handleClickLink = () => {
  const category = categories.find(
    cat => cat.slug === selectedCategory.value,
  );
  if (category) {
    const tag = slugify(category.title);

    $gtm.dispatchEvent({
      event_category: 'click',
      event_action: `blog-${tag}:link:ler-mais`,
      event_label: 'home',
    });
  }
};

onMounted(() => {
  if (status.value === 'error') throw createError(error.value);
});
</script>

<style scoped lang="scss">
.blog-desktop {
  max-width: 1078px;
  margin: 0 auto;
  .blog__cards {
    padding-left: space(5);
    gap: space(5);
    justify-content: center;
  }
}
.blog-mobile {
  .blog__cards {
    gap: space(5);
    padding-left: space(5);
  }
}
.bg-neutral-fluid {
  background: color(neutral, light);
  @media (max-width: $tablet-breakpoint) {
    padding-bottom: 48px;
  }
}
.curved {
  background-color: color(neutral, light);
  border-bottom-left-radius: 50% 60%;
  border-bottom-right-radius: 50% 60%;
  height: 40px;
  width: 100%;
}
</style>
