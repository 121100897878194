import { useAuthStore } from "@petlove/lion/store/auth";
import { consoleLionMessage } from "@petlove/lion/utils/consoleLionMessage";

import type { Session as SessionType } from "@petlove/lion/types/session";
import type { LionConfigurations } from "@petlove/lion/types/lionConfigs";

export default defineNuxtRouteMiddleware(async () => {
  if (!import.meta.server) return;

  const configs = useRuntimeConfig();
  const lionConfigs = (configs.lion as Partial<LionConfigurations>) ?? {};
  const greyhoundURL = configs.public.greyhoundURL;

  if (!lionConfigs.middleware?.fetchSessionOnServer) {
    if (import.meta.dev)
      consoleLionMessage("middleware", "fetchSessionOnServer - skipped");
    return;
  }

  await callOnce(async () => {
    try {
      const headers = useRequestHeaders(["cookie"]);
      const session: SessionType = await $fetch(
        `${greyhoundURL}/service/user/current`,
        { headers }
      );
      useAuthStore().setSession(session);
    } catch (error) {
      console.error({ error });
    }
  });

  if (import.meta.dev)
    consoleLionMessage("middleware", "fetchSessionOnServer - executed");
});
