import { isBot } from '@petlove/lion/libs/isBot';
import {
	getCookie,
	COOKIE_FINGERPRINT,
} from '@petlove/lion/libs/cookies';
import { customEvent } from '@petlove/lion/libs/analytics/events/customEvents';

import { useAuthStore } from '@petlove/lion/store/auth';
import { controlPluginExecution } from '@petlove/lion/utils/controlPluginExecution';

export default defineNuxtPlugin({
	name: 'google-login',
	parallel: true,
	dependsOn: ['user-session'],
	setup() {
		const configs = useRuntimeConfig();
		
		const shouldRunPlugin = controlPluginExecution(configs, 'googleLogin');
		if (!shouldRunPlugin) return;

		googleLogin();
	},
});

function googleLogin() {
	const authStore = useAuthStore();
	const config = useRuntimeConfig();

	const loginIsReady = async (res) => {
		const { credential } = res;
		const nuxtApp = useNuxtApp();

		try {
			authStore.setLoadingOneTap(true);
			const response = await $fetch(
				'/session/auth/sso/redirect_url',
			);
			const url = new URL(response.auth.url);

			url.searchParams.append('gt', credential);
			url.searchParams.append('plf', getCookie(COOKIE_FINGERPRINT));

			customEvent('click', 'one_tap:login', 'login');
			window.location = url.toString();
		}
		catch (error) {
			authStore.setLoadingOneTap(false);
			nuxtApp.$honeybadger?.notify(error, 'Erro ao tentar realizar o login.');
		}
	};

	const scriptIsReady = () => {
		window.google?.accounts?.id?.initialize({
			client_id: config.public?.googleLoginClientId,
			callback: loginIsReady,
		});

		window.google?.accounts?.id.prompt((notification) => {
			if (notification.isDisplayed())
				customEvent('view_element', 'one_tap', 'login', 1);
		});
	};

	const initialize = () => {
		if (!config.public?.googleLoginClientId) {
			return;
		}
		useHead({
			script: [
				{
					src: 'https://accounts.google.com/gsi/client',
					defer: true,
					onload: scriptIsReady,
					async: true,
				},
			],
		});
	};

	const userAgentBot = isBot(window?.navigator?.userAgent ?? '');
	if (!userAgentBot && !authStore.isLogged) {
		initialize();
	}
}
