import { webviewDispatchEvent } from '@petlove/lion/libs/analytics/webview';
import { useUiStore } from '@petlove/lion/store/ui';
import { controlPluginExecution } from '@petlove/lion/utils/controlPluginExecution';

const customEvent = 'custom_event';

const dispatchGtmEvent = ({
  event_category,
  event_action,
  event_label = '',
  event_noninteraction = 0,
  event_value = 0,
  value = 0.00,
}) => {
  // Obtenha a instância do store usando `useUiStore`
  const uiStore = useUiStore();
  const { webView } = uiStore;

  // Envia evento para WebView se disponível
  if (webView) {
    return webviewDispatchEvent(customEvent, {
      event_category,
      event_action,
      event_label,
      event_noninteraction,
      event_value,
      value,
    });
  }

  // Caso contrário, envia para o dataLayer do Google Tag Manager
  try {
    window.dataLayer?.push({
      event: customEvent,
      event_category,
      event_action,
      event_label,
      event_noninteraction,
      event_value,
      value,
    });
  } catch (error) {
    console.error(`Error sending data to GA: ${error}`);
  }
};

export default defineNuxtPlugin(() => {
  const configs = useRuntimeConfig();
  
  const shouldRunPlugin = controlPluginExecution(configs, 'gtm');
  if (!shouldRunPlugin) return;

  return {
    provide: {
      gtm: {
        dispatchEvent: dispatchGtmEvent,
      },
    },
  };
});
