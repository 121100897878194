import type { RuntimeConfig } from 'nuxt/schema';
import type { LionConfigurations } from '@petlove/lion/types/lionConfigs';
import { consoleLionMessage } from '@petlove/lion/utils/consoleLionMessage';

export const controlPluginExecution = (configs: RuntimeConfig, name: string) => {
    const lionConfigs = import.meta.server ? configs.lion as Partial<LionConfigurations> : configs.public.lion as Partial<LionConfigurations>;
    const pluginConfigurations = lionConfigs?.plugin ?? {};

    const hasConfiguration = Object.hasOwn(pluginConfigurations, name);
    if (hasConfiguration && !pluginConfigurations[name]) {
        if (import.meta.dev) consoleLionMessage('plugin', `${name} - skipped`);
        return false;
    }

    if (import.meta.dev) consoleLionMessage('plugin', `${name} - executed`);
    return true;
}